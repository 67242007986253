import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import { format, set } from "date-fns";
import useGetSales, {
    EModules,
    IAPIDealRatingsV2,
    TDealRatingOrderKeys,
    getSalesAsync,
} from "api/useGetSales";
import { ESortOrder, ISortState } from "components/Table/types";
import {
    TActionProps,
    TGeneratePdfFunction,
    TGenerateXLSFunction,
} from "components/Table/components/ActionButtons/types";
import DateRange, { IDateRangeProps } from "components/DateRange";
import apiDateFormat from "constants/apiDateFormat";
import { useDebounce } from "hooks";
import { IProviderCheckList } from "types/IProviderCheckList";
import {
    ELocalSavedFilters,
    ISavedDefaultFilters,
    getDefaultFilters,
    setDefaultFilters,
    tableFiltersWithProviders,
} from "utils/defaultFilters";
import { filterColumnsV2 } from "utils/filterColumns";
import formatOrderingAPI from "utils/formatOrderingAPI";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import { formattedDateRange, onGeneratePDFDefaultType } from "utils/helper";
import commonDateRanges from "constants/commonDateRanges";
import { useUrlQuery } from "hooks/useUrlQuery";
import { DealRatingsUrlParams } from "utils/urlParams";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import { Box, Text } from "@chakra-ui/react";
import DataTable from "components/v2/DataTable";
import { CustomizeProvider } from "components/v2/CustomizeProvider";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { ColumnSizingState, PaginationState, SortingState, VisibilityState } from "@tanstack/react-table";
import { getColumns, getPdfColumns, getXLSColumns } from "./constants";

type TSavedFilters = ISavedDefaultFilters[ELocalSavedFilters.DealRatingsTable];

const DealRatings = () => {

    const userAuth = useAppSelector(selectUserData);

    const { getParam, getMultipleParams, setMultipleParams } = useUrlQuery<DealRatingsUrlParams>();
    

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");
    const sourceParams = getMultipleParams("source");
    const sortByParams = getParam("sortBy");
    const searchParams = getParam("search");

    const defaultFilters = getDefaultFilters<TSavedFilters>(
        ELocalSavedFilters.DealRatingsTable,
        tableFiltersWithProviders
    );

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultFilters.columnVisibility || {});

    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(defaultFilters.columnSizing || {});
    
    const [dateRange, setDateRange] = useState<IDateRangeProps["value"]>(
        {
            startDate: startDateParams ? new Date(startDateParams) : defaultFilters.dateRange.startDate,
            endDate: endDateParams ? new Date(endDateParams) : defaultFilters.dateRange.endDate,
        }
    );

    // const [offset, setOffset] = useState<number>(defaultOffset);
    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);
    const [providers, setProviders] = useState<IProviderCheckList[]>(
        defaultFilters.providers
    );
    const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");

    const quickSearchApi = useDebounce<string>(quickSearch, 350);

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", "") as TDealRatingOrderKeys,
        desc: sortByParams.startsWith("-") ? true : false,
    }] : [{ id: 'stock_number', desc: true }]);


    useEffect(() => {

        const selectedProviders = providers.filter(({ value }) => value).map(({ id }) => id);

        setMultipleParams({
            startDate: format(dateRange.startDate, apiDateFormat),
            endDate: format(dateRange.endDate, apiDateFormat),
            source: selectedProviders,
            inventory: userAuth.inventory.value,
            // search: quickSearchApi
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange.endDate, dateRange.startDate, userAuth.inventory.value]);

    // get 3rd party providers
    const fetchProvders = useMemo(() => ({
        module: EProvidersModules.ThirdPartyProviderV2,
        params: {
            dealership_uid: userAuth.dealerV2.uid,
            inventory: userAuth.inventory.value
        }
    }), [userAuth.dealerV2.uid, userAuth.inventory.value]);

    const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);

    // set providers
    useMemo(() => {
        if (!providerList.isFetching && providerList.data) {
            const tempProviders = ((providerList.data as any).results as any[]).map((provider) => {
                return {
                    id: provider.value,
                    label: provider.label,
                    value: sourceParams?.includes(provider.value.toString()) || false,
                }
            });
            setProviders(tempProviders);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [providerList.data, providerList.isFetching]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: 'stock_number',
                order: ESortOrder.Ascending,
            };
        }
        return {
            column: sortBy[0].id as TDealRatingOrderKeys,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
            module: EModules.DealRatingsV2,
            params: {
                date_from: format(dateRange.startDate, apiDateFormat),
                date_to: format(dateRange.endDate, apiDateFormat),
                dealership_uid: userAuth.dealerV2.uid,
                page: page + 1,
                page_size: pageSize,
                // limit: pageSize,
                // offset,
                ordering: formatOrderingAPI<TDealRatingOrderKeys>(sortedBy),
                search: quickSearchApi,
                source: providers.filter(({ value }) => value).map(({ id }) => id),
                inventory: userAuth.inventory.value
            },
        }),
        [dateRange.endDate, dateRange.startDate, page, pageSize, providers, quickSearchApi, sortedBy, userAuth.dealerV2.uid, userAuth.inventory.value]
    );
    const { data, isFetching } =
        useGetSales<EModules.DealRatingsV2>(fetchParameters);

    const activeIndexQuickOption = useMemo(
        () =>
            commonDateRanges.findIndex(
                ({ value: quickOptionValue }) =>
                    format(quickOptionValue.startDate, apiDateFormat) ===
                    format(dateRange.startDate, apiDateFormat) &&
                    format(quickOptionValue.endDate, apiDateFormat) ===
                    format(dateRange.endDate, apiDateFormat)
            ),
        [dateRange.endDate, dateRange.startDate]
    );

    const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading) => {
            setIsLoading(true);

            try {
                let hasNextPage = true;
                let collectedResults: IAPIDealRatingsV2[] = [];
                // let mapOffset = 0;

                let tempPage = 1;
                do {
                    const { next, results } = await getSalesAsync<EModules.DealRatingsV2>({
                        ...fetchParameters,
                        params: {
                            ...fetchParameters.params,
                            page: tempPage
                            // limit: pageSize,
                            // offset: mapOffset
                        },
                    })();
                    collectedResults = [...collectedResults, ...results];

                    tempPage++;

                    hasNextPage = !!next;
                    // mapOffset = mapOffset + pageSize;
                } while (hasNextPage);

                // filter providers
                const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
                    // cgr_deal_rating
                    // atc_deal_rating
                    // ccm_deal_rating
                    return providers.id.toLowerCase();
                });;


                // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
                const filteredPDFColumns = getPdfColumns(userAuth.inventory.value).filter(({ key }) => {
                    if (key.includes('_deal_rating')) {
                        return filteredProviders.includes(key.split('_deal_rating')[0]);
                    }
                    return true;
                });

                const bodyFormatted = collectedResults.map((values) => {
                    return filteredPDFColumns.map(
                        ({ func, key }) =>
                            func?.(values[key], values) || (values[key] as string)
                    );
                });

                const dateRangeTitle = activeIndexQuickOption !== -1 ? `${commonDateRanges[activeIndexQuickOption].label} (${formattedDateRange(dateRange)})` : formattedDateRange(dateRange);

                onGeneratePDF(
                    {
                        head: [filteredPDFColumns.map(({ label }) => label)],
                        body: bodyFormatted,
                        ...onGeneratePDFDefaultType(`Deal Ratings by Channel`, dateRangeTitle)
                    },
                    "deal-ratings-by-channel.pdf",
                    {
                        orientation: "landscape",
                        compress: true
                    }
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export PDF file. Please contact your admin");
            }
        },
        [providers, userAuth.inventory.value, activeIndexQuickOption, dateRange, fetchParameters]
    );

    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
            setIsLoading(true);

            try {
                let hasNextPage = true;
                let collectedResults: IAPIDealRatingsV2[] = [];
                // let mapOffset = 0;

                let tempPage = 1;
                do {
                    const { next, results } = await getSalesAsync<EModules.DealRatingsV2>({
                        ...fetchParameters,
                        params: {
                            ...fetchParameters.params,
                            page: tempPage,
                            // limit: pageSize,
                            // offset: mapOffset
                        },
                    })();
                    collectedResults = [...collectedResults, ...results];

                    tempPage++;

                    hasNextPage = !!next;
                    // mapOffset = mapOffset + pageSize;
                } while (hasNextPage);

                // filter providers
                const filteredProviders = providers.filter(({ value }) => value).map((providers) => {
                    // cgr_deal_rating
                    // atc_deal_rating
                    // ccm_deal_rating
                    return providers.id.toLowerCase();
                });;


                // create a filter in pdfColumns to filter out the columns that are not in the filteredProviders with a same prefix in the key deal_rating then all other key should include without the deal_rating
                const filteredXLSColumns = getXLSColumns(userAuth.inventory.value).filter(({ key }) => {
                    if (key.includes('_deal_rating')) {
                        return filteredProviders.includes(key.split('_deal_rating')[0]);
                    }
                    return true;
                });

                const bodyFormatted = collectedResults.map((values) => {
                    return filteredXLSColumns.map(
                        ({ func, key }) =>
                            func?.(values[key], values) || (values[key] as string)
                    );
                });

                generateXLS(
                    {
                        head: [filteredXLSColumns.map(({ label }) => label)],
                        body: bodyFormatted,
                    },
                    "deal-ratings-by-channel.xls",
                );
                setIsLoading(false);
            } catch (err) {
                toast.error("Can't export XLS file. Please contact your admin");
            }
        },
        [providers, userAuth.inventory.value, fetchParameters]
    );

    const filteredColumns = useMemo(
        () => filterColumnsV2(getColumns(userAuth.inventory.value), providers),
        [providers, userAuth.inventory.value]
    );

    useEffect(() => {
        setDefaultFilters<TSavedFilters>(ELocalSavedFilters.DealRatingsTable, {
            dateRange,
            pageSize,
            providers,
            columnSizing,
            columnVisibility
        });
    }, [columnSizing, columnVisibility, dateRange, pageSize, providers]);

    const onProviderChange = (selectedProvider: IProviderCheckList) => {
        setProviders((prevProviders) =>
            prevProviders.map((provider) =>
                provider.id === selectedProvider.id
                    ? { ...provider, value: !provider.value }
                    : provider
            )
        );
        setSortBy([{ id: 'stock_number', desc: true }]);
    };

    const onDateChange = (newValue: [Date, Date]) => {
        // setDateRange({ startDate, endDate });
        setDateRange({
            startDate: set(newValue[0], { hours: 0, minutes: 0, seconds: 0 }),
            endDate: set(newValue[1], { hours: 23, minutes: 59, seconds: 59 }),
        });
    };

    const onPageChange = useCallback((pagination: PaginationState) => {
        setPage(pagination.pageIndex);
        setPageSize(pagination.pageSize);
    }, []);

    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
        setMultipleParams({
          sortBy: sort.length > 0 ? `${sort[0].desc ? '-' : ''}${sort[0].id}` : '',
        });
    }, []);

    const onSearch = useCallback((search: string) => {
        setQuickSearch(search);
        setPage(0);
        setMultipleParams({
          search: search,
        });
    }, []);
    
    const columnSidebarChilrend = () => {
        return (
            <Box display="flex" flexFlow="column" gap="10px">
                <Text fontWeight="600">Providers</Text>
                <CustomizeProvider providers={providers} onChange={onProviderChange} />
            </Box>
        );
    };

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
            >
                <Box display="flex" alignItems="center" gap="15px" flex="1" />
                <Box>
                    <ActionButtonV2
                        actionConfig={{
                            isDownloadPDF: true,
                            isPrintPDF: true,
                            isEmailPDF: true,
                            isDownloadXLS: true,
                            isEmailXLS: true,
                        }}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };

    return (
        <>
            <Box display="flex" flexDirection="column" gap="15px">
                <DateRange
                    onChange={onDateChange}
                    quickOptions={commonDateRanges}
                    value={dateRange}
                />
                <DataTable<IAPIDealRatingsV2>
                    data={data?.results || []}
                    rowCount={data?.count || 0}
                    onPageChange={onPageChange}
                    sort={sortBy}
                    onSort={onSort}
                    columns={filteredColumns as any}
                    pageIndex={page}
                    pageSize={pageSize}
                    isLoading={isFetching}
                    conttroller={conttroller()}
                    onSearch={onSearch}
                    search={quickSearch}
                    pageCount={data?.total_pages}
                    onColumnVisibilityChange={setColumnVisibility}
                    columnVisibility={columnVisibility}
                    onColumnSizingChange={setColumnSizing}
                    columnSizing={columnSizing}
                    showColumns={true}
                    // onUpdateData={updateData}
                    columnsSidebarChildren={columnSidebarChilrend()}
                    triggerButtonType="text"
                />
            </Box>
        </>
    );
};
export default DealRatings;
