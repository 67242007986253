import { Box, Button, Icon } from "@chakra-ui/react";
import useGetSales, { EModules, getSalesAsync, TRecentActivityReportOrderKeys, TTipReportOrderKeys, TTipReportV2Results } from "api/useGetSales";
import DataTable from "components/v2/DataTable";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ELocalSavedFilters, getDefaultFilters, ISavedDefaultFilters, setDefaultFilters } from "utils/defaultFilters";
import { columns, pdfColumns, sourceOptionsFilter, xlsColumns } from "./constants";
import { defaultPageSize } from "constants/defaultPageSizes";
import { useAppSelector } from "hooks/store";
import { selectUserData } from "store/auth/authSlice";
import useUrlQuery from "hooks/useUrlQuery";
import { TipReportV2UrlParams } from "utils/urlParams";
import { format } from "date-fns";
import { IDateRangeProps } from "components/DateRange";
import { lastWeek } from "constants/commonDateRanges";
import formatOrderingAPI from "utils/formatOrderingAPI";
import { ColumnSizingState, PaginationState, SortingState, VisibilityState } from "@tanstack/react-table";
import { ESortOrder, ISortState } from "components/Table/types";
import { IDropdownFilterKeyPair } from "components/Table";
import apiDateFormat from "constants/apiDateFormat";
import { filterColumnsV2 } from "utils/filterColumns";
import { EProviderInitials } from "types/APIProviderRanks";
import useGetProviders, { EProvidersModules } from "api/useGetProviders";
import { ActionButtonV2 } from "components/Table/components/ActionButtons";
import { TActionProps, TGeneratePdfFunction, TGenerateXLSFunction } from "components/Table/components/ActionButtons/types";
import TAggregatorColumnPDF from "types/TAggregatorColumnPDF";
import { onGeneratePDFDefaultType } from "utils/helper";
import { toast } from "react-toastify";
import TAggregatorColumnXLS from "types/TAggregatorColumnXLS";
import ProviderSelect from "components/v2/Select/ProviderSelect";
import RangePopover from "components/v2/Popover/RangePopover";
import { useDebounce } from "hooks";
import { FaMoneyBillTrendUp } from "react-icons/fa6";


type TSavedFilters = ISavedDefaultFilters[ELocalSavedFilters.TipReportTable];

const TipReportV2 = () => {

    const userAuth = useAppSelector(selectUserData);

    const { getParam, setMultipleParams } = useUrlQuery<TipReportV2UrlParams>();
    
    const dolFromParams = getParam("dol_from");
    const dolToParams = getParam("dol_to");

    const startDateParams = getParam("startDate");
    const endDateParams = getParam("endDate");


    const sourceParams = getParam("source");
    const sortByParams = getParam("sortBy");
    const searchParams = getParam("search");
    const minPriceChangeParams = getParam("min_price_change");
    const maxPriceChangeParams = getParam("max_price_change");

    
    const tableSource: null | IDropdownFilterKeyPair = useMemo(() => {
        // const sourceId = sourceParams;
        const selectedSource = sourceParams && sourceOptionsFilter.find(({ value }) => sourceParams === value);
    
        return selectedSource ? selectedSource : null;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultFilters = getDefaultFilters<TSavedFilters>(
        ELocalSavedFilters.TipReportTable,
        {
          source: sourceOptionsFilter[0],
          pageSize: defaultPageSize,
        }
    );

    const [columnVisibility, setColumnVisibility] = useState<VisibilityState>(defaultFilters.columnVisibility || {});

    const [columnSizing, setColumnSizing] = useState<ColumnSizingState>(defaultFilters.columnSizing || {});

    const defaultDateRange: IDateRangeProps["value"] = {
        startDate: startDateParams ? new Date(startDateParams) : lastWeek.startDate,
        endDate: endDateParams ? new Date(endDateParams) : lastWeek.endDate,
    };  

    const [providers, setProviders] = useState<IDropdownFilterKeyPair[]>([]);

    const min_price_change = minPriceChangeParams ? parseInt(minPriceChangeParams) : 0;
    const max_price_change = maxPriceChangeParams ? parseInt(maxPriceChangeParams) : 500;

    const [minPriceChange, setMinPriceChange] = useState<number>(min_price_change);
    const [maxPriceChange, setMaxPriceChange] = useState<number>(max_price_change);

    const [maxRange, setMaxRange] = useState<number>(max_price_change);

    const [page, setPage] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(defaultFilters.pageSize);

    const [sourceFilter, setSourceFilter] = useState<IDropdownFilterKeyPair>(
        tableSource ? tableSource : defaultFilters.source
    );

    const [sortBy, setSortBy] = useState<SortingState>(sortByParams && typeof sortByParams === 'string' ? [{
        id: sortByParams.replace("-", "") as TRecentActivityReportOrderKeys,
        desc: sortByParams.startsWith("-") ? true : false,
    }] : [{ id: 'vehicle', desc: false }]);

    const [quickSearch, setQuickSearch] = useState<string>(searchParams ?? "");

    const quickSearchApi = useDebounce<string>(quickSearch, 350);
    
    useEffect(() => {
        setMultipleParams({
          source: sourceFilter.value,
          ...(sortBy && {
            sortBy: `${sortedBy.order === ESortOrder.Descending ? '-' : ''}${sortedBy.column}`,
          }),
          // search: quickSearchApi,
          inventory: userAuth.inventory.value,
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sourceFilter, sortBy, userAuth.inventory.value]);

    const fetchProvders = useMemo(() => ({
        module: EProvidersModules.ThirdPartyProviderV2,
        params: {
          dealership_uid: userAuth.dealerV2.uid,
          inventory: userAuth.inventory.value
        }
    }), [userAuth.dealerV2.uid, userAuth.inventory.value]);

    const providerList = useGetProviders<EProvidersModules.ThirdPartyProviderV2>(fetchProvders);

    useMemo(() => {
    if (!providerList.isFetching && providerList.data) {
        const tempProviders = ((providerList.data as any).results as any[]).map((provider) => {
            return {
                label: provider.label,
                value: provider.value,
            }
        });  

        setProviders(tempProviders);
    
        // check if source filter doesn't exist in temp providers
        if (!tempProviders.some((provider) => provider.value === sourceFilter.value) && tempProviders.length > 0) {
            setSourceFilter(tempProviders[0] as IDropdownFilterKeyPair);
        }
    }
    }, [providerList.data, providerList.isFetching, sourceFilter.value]);

    const sortedBy: ISortState = useMemo(() => {

        if (!sortBy.length) {
            return {
                column: 'vehicle',
                order: ESortOrder.Ascending,
            };
        }
        return {
            column: sortBy[0].id as TTipReportOrderKeys,
            order: sortBy[0].desc ? ESortOrder.Descending : ESortOrder.Ascending,
        };
    }, [sortBy]);

    const fetchParameters = useMemo(
        () => ({
          module: EModules.TipReportV2,
          params: {
            date_from: format(defaultDateRange.startDate, apiDateFormat),
            date_to: format(defaultDateRange.endDate, apiDateFormat),
            dealership_uid: userAuth.dealerV2.uid,
            ...(dolFromParams && !isNaN(parseInt(dolFromParams)) && { 
              dol_from: parseInt(dolFromParams)
            }),
            ...(dolToParams && !isNaN(parseInt(dolToParams)) && { 
              dol_to: parseInt(dolToParams)
            }),
            // dol_range: dolRangeParams as EDaysOnLot,
            // limit: pageSize,
            // offset,
            ordering: formatOrderingAPI<TTipReportOrderKeys>(sortedBy),
            search: quickSearchApi,
            source: sourceFilter.value,
            min_price_change: minPriceChange,
            max_price_change: maxPriceChange,
            page: page + 1,
            page_size: pageSize,
          },
        }),
        [defaultDateRange.startDate, defaultDateRange.endDate, userAuth.dealerV2.uid, dolFromParams, dolToParams, sortedBy, quickSearchApi, sourceFilter.value, minPriceChange, maxPriceChange, page, pageSize]
    );
      
    const { data, isFetching } = useGetSales<EModules.TipReportV2>(fetchParameters);

    const onPageChange = useCallback((pagination: PaginationState) => {
        setPage(pagination.pageIndex);
        setPageSize(pagination.pageSize);
    }, []);
    
    const onSort = useCallback((sort: SortingState) => {
        // if (!sort.length) return;
        setSortBy(sort);
    }, []);

    const handleRangeChange = (range: number[]) => {
      setMinPriceChange(range[0]);
      setMaxPriceChange(range[1]);
    };

    const formattedTableColumns = useMemo(
        () =>
          sourceFilter.value === EProviderInitials.CarGurus ||
          (EProviderInitials.CarsDotCom && data)
            ? columns.map(({ id, ...restColumnProps }) => ({
                ...restColumnProps,
                ...(id && { id }),
                ...(id === "new_deal_rating" && {
                  header: 'New Deal Rating'
                }),
                ...(id === "new_price" && {
                  header: 'New Price'
                }),
                ...(id === "price_change" && {
                  header: 'Price Change'
                }),
              }))
            : columns,
        [sourceFilter.value, data]
    );

    const selectedSources = useMemo(
        () =>
          providers.map((source) => ({
            id: source.value,
            label: source.label,
            value: source.value === sourceFilter.value,
          })),
        [sourceFilter.value, providers]
    );

    const filteredColumns = filterColumnsV2(formattedTableColumns, selectedSources);
    
    useEffect(() => {
      setDefaultFilters<TSavedFilters>(ELocalSavedFilters.TipReportTable, {
          pageSize,
          source: sourceFilter,
          columnVisibility,
          columnSizing,
      });
    }, [columnSizing, columnVisibility, pageSize, sourceFilter]);
  
    const onGenerateXLSX: TActionProps<TGenerateXLSFunction> = useCallback(
        async (generateXLS, setIsLoading) => {
          setIsLoading(true);
    
          try {
            let hasNextPage = true;
            let collectedResults: TTipReportV2Results[] = [];
            // let mapOffset = 0;
    
            let tempPage = 1;
    
            do {
              const { next, results } = await getSalesAsync<EModules.TipReportV2>({
                ...fetchParameters,
                  params: { 
                    ...fetchParameters.params, 
                    page_size: 1000,
                    page: tempPage, 
                },
              })();
              collectedResults = [...collectedResults, ...results];
    
              hasNextPage = !!next;
              tempPage++;
            } while (hasNextPage);
    
            const filteredXLSColumn = filterColumnsV2(xlsColumns, selectedSources);
    
            const formattedXLSColumns =
              sourceFilter.value === EProviderInitials.CarGurus || EProviderInitials.CarsDotCom
                ? (filteredXLSColumn.map(({ key, ...restXLSColumnProps }) => ({
                    ...restXLSColumnProps,
                    key,
                    ...(key === "new_deal_rating" && {
                      label: "New Deal Rating",
                    }),
                    ...(key === "new_price" && {
                      label: "New Price",
                    }),
                    ...(key === "price_change" && {
                      label: "Price Change",
                    }),
                  })) as TAggregatorColumnXLS<TTipReportV2Results>[])
                : filteredXLSColumn;
    
            const bodyFormatted = collectedResults.map((values) => {
              return formattedXLSColumns.map(
                ({ func, key }) =>
                  func?.(values[key], values) || (values[key] as string)
              );
            });
    
            generateXLS(
              {
                head: [formattedXLSColumns.map(({ label }) => label)],
                body: bodyFormatted,
              },
              `tip-report-${sourceFilter.label.toLowerCase()}.xls`
            );
            setIsLoading(false);
          } catch (err) {
            toast.error("Can't export XLS file. Please contact your admin");
          }
        },
        [selectedSources, sourceFilter.value, sourceFilter.label, fetchParameters]
      );
    
      const onGeneratePDF: TActionProps<TGeneratePdfFunction> = useCallback(
        async (onGeneratePDF, setIsLoading) => {
          setIsLoading(true);
    
          try {
            let hasNextPage = true;
            let collectedResults: TTipReportV2Results[] = [];
            // let mapOffset = 0;
    
            let tempPage = 1;
    
            do {
              const { next, results } = await getSalesAsync<EModules.TipReportV2>({
                ...fetchParameters,
                  params: { 
                    ...fetchParameters.params, 
                    page_size: 1000,
                    page: tempPage, 
                },
              })();
    
              collectedResults = [...collectedResults, ...results];
    
              hasNextPage = !!next;
              tempPage++;
            } while (hasNextPage);
    
            const filteredPDFColumn = filterColumnsV2(pdfColumns, selectedSources);
    
            const filteredPDFColumns =
              sourceFilter.value === EProviderInitials.CarGurus || EProviderInitials.CarsDotCom
                ? (filteredPDFColumn.map(({ key, ...restXLSColumnProps }) => ({
                    ...restXLSColumnProps,
                    key,
                    ...(key === "new_deal_rating" && {
                      label: "New Deal Rating",
                    }),
                    ...(key === "new_price" && {
                      label: "New Price",
                    }),
                    ...(key === "price_change" && {
                      label: "Price Change",
                    }),
                  })) as TAggregatorColumnPDF<TTipReportV2Results>[])
                : filteredPDFColumn;
    
            const bodyFormatted = collectedResults.map((values) => {
              return filteredPDFColumns.map(
                ({ func, key }) =>
                  func?.(values[key], values) || (values[key] as string)
              );
            });
    
            const title = format(defaultDateRange.endDate, "MMM d, yyyy");
    
            onGeneratePDF(
              {
                head: [filteredPDFColumns.map(({ label }) => label)],
                body: bodyFormatted,
                ...onGeneratePDFDefaultType(`Tip Report - ${sourceFilter.label}`, title)
              },
              `tip-report-${sourceFilter.label.toLowerCase()}.pdf`,
              {
                orientation: "landscape",
                compress: true
              }
            );
            setIsLoading(false);
          } catch (err) {
            toast.error("Can't export PDF file. Please contact your admin");
          }
        },
        [selectedSources, sourceFilter.value, sourceFilter.label, defaultDateRange.endDate, fetchParameters]
    );

    const onSearch = useCallback((search: string) => {
      setQuickSearch(search);
      setPage(0);
      setMultipleParams({
        search: search,
      });
    }, []);

    const conttroller = () => {
        return (
            <Box
                display="flex"
                gap="15px"
                justifyContent="space-between"
                flex="1"
                alignItems="center"
                flexFlow="row wrap"
            >
                <Box
                    display="flex"
                    alignItems="center"
                    gap="15px"
                    flex="1"
                >
                    {providers && providers.length > 0 && (
                        <ProviderSelect 
                            placeholder=""
                            options={providers}
                            defaultValue={sourceFilter}
                            onChange={(value) => setSourceFilter(value)}
                        />
                    )}

                  <RangePopover
                    defaultValue={[minPriceChange, maxPriceChange]}
                    header="Price Change"
                    onChange={handleRangeChange}
                    max={maxRange}
                    onMaxChange={setMaxRange}
                    onReset={() => {
                      setMaxRange(500);
                      setMinPriceChange(0);
                      setMaxPriceChange(500);
                    }}
                  >
                    <Button 
                      leftIcon={<Icon as={FaMoneyBillTrendUp} color="#8bb9a8" fontSize="large" />}
                      size="md"
                      border="1px solid #b3b8db"
                      backgroundColor="#ffffff"
                      fontSize="14px"
                      padding="10px 16px"
                      // height="43px"
                    >
                      Price Change
                    </Button>
                  </RangePopover>
                </Box>
                <Box>
                    <ActionButtonV2 
                        actionConfig={{
                            isDownloadPDF: true,
                            isDownloadXLS: true,
                            isEmailPDF: true,
                            isPrintPDF: true,
                            isEmailXLS: true,
                        }}
                        onDownloadPDF={onGeneratePDF}
                        onDownloadXLS={onGenerateXLSX}
                        onGeneratePDF={onGeneratePDF}
                    />
                </Box>
            </Box>
        );
    };
    
    return (
        <Box
            display="flex"
            flexDirection="column"
            gap="15px"
        >
            <DataTable<TTipReportV2Results>
                data={data?.results || []}
                rowCount={data?.count || 0}
                onPageChange={onPageChange}
                sort={sortBy}
                onSort={onSort}
                columns={filteredColumns as any}
                pageIndex={page}
                search={quickSearch}
                pageSize={pageSize}
                isLoading={isFetching}
                conttroller={conttroller()}
                onSearch={onSearch}
                pageCount={data?.total_pages}
                onColumnVisibilityChange={setColumnVisibility}
                columnVisibility={columnVisibility}
                onColumnSizingChange={setColumnSizing}
                columnSizing={columnSizing}
                showColumns={true}
                triggerButtonType="icon"
                // onUpdateData={updateData}
            />
        </Box>
    );
};

export default TipReportV2;