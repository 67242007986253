import {
  // endOfYesterday,
  sub,
  startOfMonth,
  subDays,
  endOfMonth,
  subMonths,
  endOfToday,
} from "date-fns";

export type TLabels =
  | "Current Month"
  | "Current Week"
  | "Last Month"
  | "Last 30 Days"
  | "Last 60 Days"
  | "Last 90 Days"
  | "All Time"
  | "Last 7 Days";

export interface IDateRange {
  endDate: Date;
  startDate: Date;
}

export interface ICommonDateRange {
  label: TLabels;
  value: IDateRange;
}

const currentDate = new Date();

export const currentMonth = {
  endDate: endOfToday(),
  startDate: startOfMonth(currentDate),
};

export const today = {
  endDate: endOfToday(),
  startDate: currentDate,
};

export const lastWeek = {
  endDate: endOfToday(), // endOfYesterday() original value
  startDate: sub(endOfToday(), { days: 6 }),
};

export const lastMonth = {
  endDate: endOfMonth(subMonths(currentDate, 1)),
  startDate: startOfMonth(subMonths(currentDate, 1)),
};

export const lastThirtyDays = {
  endDate: endOfToday(), // endOfYesterday() original value
  startDate: subDays(endOfToday(), 29), // subDays(endOfYesterday(), 29), original value
};

export const lastSixtyDays = {
  endDate: endOfToday(), // endOfYesterday() original value
  startDate: subDays(endOfToday(), 59), // subDays(endOfYesterday(), 59), original value
};

export const lastNinetyDays = {
  endDate: endOfToday(), // endOfYesterday() original value
  startDate: subDays(endOfToday(), 89), // subDays(endOfYesterday(), 89), original value
};

export const allTime = {
  endDate: endOfToday(),
  startDate: new Date("2021-01-01"),
}

const commonDateRanges: ICommonDateRange[] = [
  {
    label: "Last 7 Days",
    value: lastWeek,
  },
  {
    label: "Last Month",
    value: lastMonth,
  },
  {
    label: "Last 30 Days",
    value: lastThirtyDays,
  },
  {
    label: "Last 60 Days",
    value: lastSixtyDays,
  },
  {
    label: "Last 90 Days",
    value: lastNinetyDays,
  },
];

export const graphDateRange: ICommonDateRange[] = [
  {
    label: "Current Month",
    value: currentMonth,
  },
  {
    label: "Last Month",
    value: lastMonth,
  },
  {
    label: "Last 60 Days",
    value: lastSixtyDays,
  },
  {
    label: "Last 90 Days",
    value: lastNinetyDays,
  },
];

export default commonDateRanges;

export const commonDateRangesV2: ICommonDateRange[] = [
  {
    label: "Last 7 Days",
    value: lastWeek,
  },
  {
    label: "Last Month",
    value: lastMonth,
  },
  {
    label: "All Time",
    value: allTime,
  }
];

export const graphDateRangeV2: ICommonDateRange[] = [
  {
    label: "Current Month",
    value: currentMonth,
  },
  {
    label: "Last Month",
    value: lastMonth,
  },
  {
    label: "All Time",
    value: allTime,
  }
];